<template>
	<div class="call-container">
		<!-- Content -->
		<section id="content">
		<div class="content-wrap">
			<div class="container clearfix">
				<div class="container" style="text-align:center">
					<h1 class="font-weight-bold title text-center" style="">Supercharge your productivity with eFlex virtual assistants</h1>
					<router-link to="/book-a-call/calendly2"><a class="callbutton button-large nott ls0" style="margin-bottom:2em;">
						Book a call with eFlex
					</a></router-link>
					<div class="container2">
						<div class="client-container">
							<img src="/assets/images/clients/manscaped.jpeg" class="clients">
						</div>
						<div class="client-container">
							<img src="/assets/images/clients/uservoice.png" class="clients">
						</div>
						<div class="client-container">
							<img src="/assets/images/clients/affinity.jpg" class="clients">
						</div>
						<div class="client-container">
							<img src="/assets/images/clients/scale.png" class="clients">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content-wrap border-top details">
			<div class="container clearfix">
				<div class="row col-mb-50 mb-0">
					<div class="col-12 center text-container">
						<h3 style="margin:0!important">Welcome to eFlex Virtual Assistants: </h3>
						<h3 style="margin:0!important;padding-bottom: 1.5em">Your Personal Support Solution</h3>
						<p style="margin:0!important;" class="custom-padding">Are you a busy professional juggling numerous tasks? Do you find yourself overwhelmed by administrative duties that take valuable time away from your core responsibilities? </p>
						<p>Look no further - eFlex Virtual Assistants are here to lighten your load.</p>
					</div>
					<div class="col-md-6">
						<img src="/assets/images/landing/L1.png" alt="CallUs">
					</div>

					<div class="col-md-6">
						<h2 class="left">Tailored Assistance.</h2>
						<p class="left">Our virtual assistants are highly adaptable and can handle a wide range of tasks - from managing your emails and scheduling appointments to conducting research and preparing reports. Whether it's personal or business-related, we've got you covered.</p>
					</div>
				</div>
				<div class="row col-mb-50 mb-0">
					<div class="col-md-6 order2">
						<h2 class="left">Efficiency.</h2>
						<p class="left">Delegate your routine administrative tasks to our skilled virtual assistants, allowing you to focus on what truly matters - growing your business or enjoying your personal time.</p>
					</div>

					<div class="col-md-6 order1">
						<img src="/assets/images/landing/L2.png" alt="CallUs">
					</div>
				</div>
				<div class="row col-mb-50 mb-0">
					<div class="col-md-6">
						<img src="/assets/images/landing/L3.png" alt="CallUs">
					</div>

					<div class="col-md-6">
						<h2 class="left">Experience and Expertise.</h2>
						<p class="left">Our team is comprised of experienced professionals who excel in various fields. You can trust us to handle your tasks with precision and professionalism.</p>
					</div>
				</div>
			</div>
		</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'CallBooking',
	components: {
	},
	mounted() {
		const callButton = document.querySelector('.callbutton');
		callButton.style.marginLeft = ''; // Remove the inline margin-left style

	}
}
</script>

<style scoped>
@media screen and (min-width: 800px) {
	.custom-padding {
		padding: 0 250px;
	}
}

@media screen and (min-width: 425px) {
	
	.title {
		position:relative;
		font-size:75px!important;
		margin-top:1em
	}
	.grid-container {
		display: grid;
		flex-direction: column-reverse;
	}
	.maincall {
		order: 1;
		margin-bottom:3em;
	}
	.container2 {
		display: flex;
		justify-content: center; 
		align-items: center; 
		flex-wrap: wrap; 
	}
	.client-container {
		flex: 1;
		text-align: center;
		margin-bottom: 0;
	}
	.clients {
		height: 50%;
		margin-left: 2em;
	}
	
}	

@media screen and (max-width: 800px) {
	.title {
		padding-top: 1em;
		font-size:50px!important;
		margin-top:0;
	}
	.logoimg {
		margin-top: 1em;
		padding-top: 1em;
	}
	.callbutton {
		margin-left: 1em!important;
		font-size: 1.2rem;
		line-height: 40px;
		padding: 10px 30px;
	}
	.client-container {
		display: block;
		text-align: center;
		margin-bottom: 2em;
	}
	.clients {
		display: inline-block;
		height: auto;
		margin: 0 auto;
	}
	.container2 .row {
		flex-direction: column;
		padding: 0 2em;
	}	
	.row .col-md-6.order1 {
		order: 1;
	}

	.row .col-md-6.order2 {
		order: 2;
	}

}

.callcontainer h1 {
	position: relative;
	font-size: 50px;
	width: 100%;
	text-align: center;
}
.callbutton {
	display: inline-block;
	position: relative;
	cursor: pointer;
	outline: none;
	background-color: #1ABC9C;
	color: #FFF;
	font-weight: 700;
	letter-spacing: 1px;
	border: none;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	padding: 15px 40px;
	font-size: 2rem;
	line-height: 50px;
}
.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details .row {
  margin-left: 0;
  margin-right: 0;
}

.details .col-md-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.details .col-md-6 img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.details .left {
  text-align: left;
}

.clients {
	max-height: 100px;
}
.left {
	text-align: left;
}
.col-md-6 {
	padding-bottom: 0!important;
}

</style>